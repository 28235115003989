<script>
  import numericInput from '../components/numeric-input'
  import currencyInput from '../components/currency-input'
  import customBtnRadioGroup from '../components/custom-btn-radio-group'
  import customBtnCheckbox from '../components/custom-btn-checkbox'
  import _ from 'lodash';
  import customVueSelect from "./custom-vue-select";
  import fillLeasingPrices from '../components/fill-leasing-prices'
  import {t_scoped} from '../i18n/i18n';

  const t_leasing = t_scoped('leasing');

  export default {
    name: "leasing-price-form",
    components: {numericInput, fillLeasingPrices, currencyInput, customBtnRadioGroup, customBtnCheckbox, customVueSelect},
    props: {
      leasing_prices: {type: Array},
      blueprint: {type: Object},
      is_admin: {type: Boolean, default: false},
      jLeasing: {type: Object},
      canHavePremium: {type: Boolean, default: false},
      locale: {type: String, default: undefined},
      errors: {type: Object},
      subscription: {type: Boolean, default: false},
    },
    data() {
      return {}
    },
    computed: {

      deFormatRegex() {
        return new RegExp(`[^\\d${this.separator}+-]`, 'g')
      },

      monthly_rates() {
        return this.leasing_prices.map((leasing_price, index) => {

          leasing_price.initial_payments.map(initial_payment => {
            initial_payment.monthly_payment = leasing_price.leasing_rate - initial_payment.initial_payment / leasing_price.duration_months;
            return initial_payment;
          });

          return leasing_price.initial_payments;
        });
      },

      has_premium() {
        if (this.jLeasing.has_premium === true || this.jLeasing.has_premium === 'true') {
          return true;
        }
        else {
          this.jLeasing.premium_value = null;
          return false;
        }
      },
    },
    methods: {
      t_leasing,

      /**
       * Needed to correct forms name attribute with rails form helper index,
       * used to be able to easily take form submit result into activeRecord update.
       *
       * @param name
       * @param indexes Array   Array of indexes, depending on depth of cascade VUE component is placed
       * @returns {*}
       */
      correctNameIndex(name, indexes) {
        let counter = 0;
        return name.replace(/\[\d+\]/gm, () => {
          let returnVal = '['.concat(indexes[counter], ']');
          counter++;
          return returnVal;
        });
      },

      /**
       * Needed to correct forms id attribute with rails form helper index,
       * used to be able to easily take form submit result into activeRecord update.
       *
       * @param id
       * @param indexes Array   Array of indexes, depending on depth of cascade VUE component is placed
       * @returns {*}
       */
      correctIdIndex(id, indexes) {
        let counter = 0;
        return id.replace(/_\d+_/gm, () => {
          let returnVal = '_'.concat(indexes[counter], '_');
          counter++;
          return returnVal;
        });
      },

      /**
       * ADD / REMOVE LeasingPrice HANDLING
       */
      addLeasingPrice() {
        let clonedLeasingPrices = _.cloneDeep(this.leasing_prices);
        const blueprint = _.cloneDeep(this.blueprint);
        blueprint.key = `KEY_${Math.round(Math.random() * 1000000000)}`;
        blueprint.initial_payments = [{initial_payment: 0, best_price: false, monthly_payment: 0}];
        clonedLeasingPrices.push(blueprint);

        this.$emit('leasingPricesChanged', clonedLeasingPrices);
      },
      removeLeasingPrice(index) {
        let clonedLeasingPrices = _.cloneDeep(this.leasing_prices);

        if (clonedLeasingPrices[index].id) {
          clonedLeasingPrices[index].deleted = true;
        } else {
          clonedLeasingPrices.splice(index, 1);
        }

        this.$emit('leasingPricesChanged', clonedLeasingPrices);
      },

      /**
       * ADD / REMOVE InitialPayment HANDLING
       */
      addInitialPayment(index) {
        let clonedLeasingPrices = _.cloneDeep(this.leasing_prices);
        const blueprint = _.cloneDeep(this.blueprint);
        blueprint.key = `KEY_${Math.round(Math.random() * 1000000000)}`;
        blueprint.initial_payment = 0;
        blueprint.monthly_payment = 0;
        blueprint.best_price = false;
        clonedLeasingPrices[index].initial_payments.push(blueprint);

        this.$emit('leasingPricesChanged', clonedLeasingPrices);
      },
      removeInitialPayment(index, initial_payments_index) {
        let clonedLeasingPrices = _.cloneDeep(this.leasing_prices);

        if (clonedLeasingPrices[index].initial_payments[initial_payments_index].id) {
          clonedLeasingPrices[index].initial_payments[initial_payments_index].deleted = true;
        } else {
          clonedLeasingPrices[index].initial_payments.splice(initial_payments_index, 1);
        }

        this.$emit('leasingPricesChanged', clonedLeasingPrices);
      },

      /**
       * ERROR HANDLING: LeasingPrices
       *
       * @param attrName
       * @param index
       * @returns {{field_with_errors: *}}
       */
      errorClassFor(attrName, index) {
        return {'field_with_errors': this.hasErrors(attrName, index)}
      },
      errorMessageFor(attrName, index) {
        return (this.errors[`leasing_prices[${index}].${attrName}`] || []).join(", ");
      },
      hasErrors(attrName, index) {
        return this.errors.hasOwnProperty(`leasing_prices[${index}].${attrName}`);
      },

      /**
       * ERROR HANDLING: InitialPayments
       */
      initialPaymentsErrorClassFor(attrName, index, initialPaymentsIndex) {
        return {'field_with_errors w-75': this.initialPaymentsHasErrors(attrName, index, initialPaymentsIndex)}
      },
      initialPaymentsErrorMessageFor(attrName, index, initialPaymentsIndex) {
        return (this.errors[`leasing_prices[${index}].initial_payments[${initialPaymentsIndex}].${attrName}`] || []).join(", ");
      },
      initialPaymentsHasErrors(attrName, index, initialPaymentsIndex) {
        return this.errors.hasOwnProperty(`leasing_prices[${index}].initial_payments[${initialPaymentsIndex}].${attrName}`);
      },

      /**
       * Returns formatted monthly rate.
       * @param leasing_price_index
       * @param initial_payment_index
       * @returns {string}
       */
      monthly_rates_formatted(leasing_price_index, initial_payment_index) {
        let monthly_payment = this.monthly_rates[leasing_price_index][initial_payment_index].monthly_payment;
        if (!_.isFinite(monthly_payment)) {
          return "";
        }
        return monthly_payment.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    },

  }
</script>
